const optsCommon = {
    lic: 'LICENSEE'
    , env: 'LICENSEE_ENV'
    , appVersion: 'APP_VERSION'
    , appBuildDate: 'APP_BUILD_DATE'
    , language: 'en'
    , languageCode: 'en-US'
    , filterByCulture: false
    , eventSearchAPIArgs: 'api-version=2017-11-11'
        + '&api-key=847C34C8144968D5364912EF73479440'
        + '&suggesterName=s1'
        + '&fuzzy=true'
        + '&$select=ObjectKey,Search4,Search1,ObjectType,ObjectID,ObjectSeq,Weight,EventDate,CityState'
        + '&$top=100'
        + '&$filter=(EventDate eq null or EventDate ge ' + new Date().toISOString() + ')' + ' {culture}'
        + '&search={searchText}'

    , searchText: 'searchText={textToSearch}'

    // name is shown in UI and value is corresponding url
    , defaultSuggestions: [
        { name: 'Concerts', value: 'concert' }
        , { name: 'MLB', value: 'mlb' }
        , { name: 'NBA', value: 'nba' }
        , { name: 'NHL', value: 'nhl' }
        , { name: 'NFL', value: 'nfl' }
    ]

    // name is shown in UI and value is corresponding url
    , defaultUserEventSuggestions: [
        { name: 'Women\'s March', value: 'womens-march' }
        , { name: 'Concerts', value: 'concert' }
        , { name: 'NFL', value: 'nfl' }
        , { name: 'NBA', value: 'nba' }
        , { name: 'NHL', value: 'nhl' }
    ]
}

const optsCommonDE = {
    language: 'de'
    , languageCode: 'de-DE'
    , filterByCulture: true
    // name is shown in UI and value is corresponding url
    , defaultSuggestions: [
        { name: 'FC Bayern Basketball', value: 'fc-bayern-basketball' }
        , { name: 'Konzerte', value: 'concert' }
        , { name: 'Fußball', value: 'soccer' }
    ]

    // name is shown in UI and value is corresponding url
    , defaultUserEventSuggestions: [
        { name: 'FC Bayern Basketball', value: 'fc-bayern-basketball' }
        , { name: 'Konzerte', value: 'concert' }
        , { name: 'Fußball', value: 'soccer' }
    ]
}

const optsCommonPTBR = {
    language: 'pt'
    , languageCode: 'pt-BR'

    // name is shown in UI and value is corresponding url
    , defaultSuggestions: [
        { name: 'Futebol', value: 'futebol' }
        , { name: 'Show', value: 'show' }
        , { name: 'Religioso', value: 'religioso' }
    ]

    // name is shown in UI and value is corresponding url
    , defaultUserEventSuggestions: [
        { name: 'Futebol', value: 'futebol' }
        , { name: 'Show', value: 'show' }
        , { name: 'Religioso', value: 'religioso' }
    ]
}


    , optsDevk8s = {

        eventSearchAPI: 'https://rally-search.search.windows.net'
            + `/indexes/rally-search-index-dev/docs/suggest`
            + '?' + optsCommon['eventSearchAPIArgs']
        , ...optsCommon
    }

    , optsQak8s = { 

        // eventSearchAPI: 'https://rally-search.search.windows.net'
        //     + `/indexes/rally-search-index-dev/docs/suggest`
        //     + '?' + optsCommon['eventSearchAPIArgs']
        
        eventSearchAPI: 'https://gapi.stg.rally.co/ElasticSearch/EventSearch' + '?' + optsCommon['searchText']
        , ...optsCommon
    }

    , optsProdk8s = {
        eventSearchAPI: 'https://gapi-prod.rally.co/ElasticSearch/EventSearch' + '?' + optsCommon['searchText']
        , ...optsCommon
    }

export default (lang) => {
    var config = optsProdk8s;

    if (lang !== undefined) {
        if (lang === "de") {
            config = { ...config, ...optsCommonDE };
        }
        if (lang === "pt-br") {
            config = { ...config, ...optsCommonPTBR };
        }
    }
    return config;
}