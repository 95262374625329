import m from 'mithril'
import i18next from 'i18next'
import i18nManager from '../utils/i18n-mgr'

export default (model) => {
    // i18n
    initI18nManager()

    addEventListener()

    return {
        configSearchBox
        , suggestionsByCategory
        , afterSilence
        , getNextData
        , sort
        , choose
        , getStaticText
    }

    function initI18nManager() {
        i18nManager.init(model.settings.language)
    }

    function getStaticText(key, params = {}) {
        return i18next.t(key, params)
    }

    function configSearchBox() {
        console.log('Type: ', model.type)
        console.log('Theme: ', model.theme)
        let manuelBox = document.getElementById(model.id)
        manuelBox.classList.add(`${model.type}__page`)
        manuelBox.classList.add(`${model.theme}__theme`)

        // Input box configuration
        let searchInput = document.getElementsByClassName('manuel-complete')[0].children[0]
        searchInput.id = "search__rally-ip"
        searchInput.placeholder = model.inputPlaceholder || getStaticText(model.defaultInputPlaceholderKey)

        // Sugegestion box configuration
        let suggestion_box = document.getElementsByClassName('manuel-complete')[0].children[1]
        suggestion_box.classList.add('search__dropdown')
        // Add placeholder in result to show if there is no default search
        suggestion_box.pseudoStyle("before", "content", `'${getStaticText('search-info')}'!important`);
    }

    function addEventListener() {
        /* ========== Handle SearchDropDown Show / Hide START ========== */

        // Focus Event
        let focusSetTimeout = setTimeout(() => {
            if (document.getElementById('search__rally-ip')) {
                document.getElementById('search__rally-ip')
                    .addEventListener('focus', (e) => handleSearchDropDown(e, 'focus'));
                clearTimeout(focusSetTimeout);
            }
        }, 100);

        // Click Event
        document.body.addEventListener('click', (e) => handleSearchDropDown(e, 'click'));

        function handleSearchDropDown(e, type) {
            let searchInput = document.getElementById('search__rally-ip')
                , searchDropDown = document.getElementsByClassName('search__dropdown');

            if (e.target.id == 'search__rally-ip') {
                // Show DropDown on input click
                if (type == 'click') {
                    searchDropDown[0].classList.add('__show__dropdown')
                    window.scrollBy(0, searchInput.getBoundingClientRect().top - 50)
                }

                // Dispatched onSearch event
                searchInput.dispatchEvent(new CustomEvent('onSearch', { detail: { isActive: true } }))

                // Blur Input on scroll of search dropdown
                searchDropDown[0].addEventListener('scroll', function (e) {
                    searchInput.blur()
                })

                // Blur Input on enter of search input
                searchInput.addEventListener('keydown', function (e) {
                    (e.key.toLowerCase() == 'enter') &&
                        searchInput.blur();
                })
            } else {
                // Hide DropDown on input click
                if (searchDropDown && searchDropDown.length > 0) {
                    searchDropDown[0].classList.remove('__show__dropdown')
                }

                // Dispatched onSearch event
                if (searchInput) {
                    searchInput.dispatchEvent(new CustomEvent('onSearch', { detail: { isActive: false } }))
                }
            }
        }

        // Hide DropDown on Esc button press
        document.onkeydown = function (e) {
            e = e || window.event;
            if (
                e.key === "Escape"
                && document.getElementsByClassName('search__dropdown')
                && document.getElementsByClassName('search__dropdown').length > 0
            ) {
                document.getElementById('search__rally-ip').blur()
                document.getElementsByClassName('search__dropdown')[0].classList.remove('__show__dropdown')
            }
        }
    }

    // will group api results by category(read ObjectType)
    function suggestionsByCategory(items) {
        return indexBy(items, o => o.ObjectType)
    }

    function indexBy(items, f) {
        return items.reduce((p, n) => (
            p[f(n)] = (p[f(n)] || []).concat(n)
            , p
        ), {})
    }

    function afterSilence(value) {
        /* Open dropdown if not on valid value*/
        if (value) {
            let searchDropDown = document.getElementsByClassName('search__dropdown')
            if (
                searchDropDown[0]
                && !searchDropDown[0].classList.contains('__show__dropdown')
            ) {
                searchDropDown[0].classList.add('__show__dropdown')
            }
        }
        model.manuel.input = value
        model.lastChosen = value
        /* reset nextURL on input change */
        getNextData('')

        clearTimeout(model.timeoutId);

        var filterCulture = model.settings.filterByCulture ? ' and Culture eq \'' + model.settings.languageCode + '\'' : "";

        // model.timeoutId = setTimeout(function () {
        //     model.lastChosen != model.manuel.chosen
        //         ? requesApi({
        //             method: 'GET'
        //             , url: model.settings.eventSearchAPI.replace('{searchText}', encodeURIComponent(model.lastChosen)).replace('{culture}', encodeURIComponent(filterCulture)) //+ `&filter=(EventTime eq null)`
        //         })
        //         : requesApi({})
        // }, model.manuel.input.trim() != '' ? model.timeOutTime : 0);


        model.timeoutId = setTimeout(function () {
            model.lastChosen != model.manuel.chosen
                ? requesApi({
                    method: 'GET'
                    , url: model.settings.eventSearchAPI.replace('{textToSearch}', encodeURIComponent(model.lastChosen))
                })
                : requesApi({})
        }, model.manuel.input.trim() != '' ? model.timeOutTime : 0);
    }

    // Here we will get the api results. Also, here is the chance to modify/filter results data per convinience before passing to "items" field.
    function requesApi(req) {
        // default suggestion data if query produce no result
        let defaultSuggestionArray = (model.isShowDefaultSuggetion
            ? model.type == 'user-event'
                ? model.settings.defaultUserEventSuggestions
                : model.settings.defaultSuggestions
            : []).map(category => {
                return {
                    isDefault: true
                    , Search1: category['name']
                    , ObjectKey: category['value']
                    , ObjectType: getStaticText('browse-categories')
                }
            });

        ((req && req.url && req.url.indexOf('search=~') == -1)
            ? m.request(req)
            : Promise.resolve({ value: [] }))
            .then(({ value: xs, '@odata.nextLink': nextApi }) => {
                let score = -999, topResult
                xs = model.manuel.input.trim() != '' && xs.length
                    ? xs.filter(d => d.Search1)
                    : defaultSuggestionArray.filter(ds => ds.Search1);

                xs.forEach(d => {
                    //d.ObjectType.toLowerCase() == 'cp' && (d.ObjectType = 'venue');
                    d.prevObjectType = d.ObjectType
                    d.ObjectTypeText = d.ObjectType.toLowerCase() == getStaticText(d.ObjectType.toLowerCase()) ? d.ObjectType : getStaticText(d.ObjectType.toLowerCase());
                    d.Search1 = d.Search1.split('~=')[d.Search1.split('~=').length - 1]
                    d.Search1 = `${d.prevObjectType}~${d.ObjectKey}~=${d.Search1}`;

                    // for top category
                    d['Weight'] > score && (
                        score = d['Weight']
                        , topResult = Object.assign({}, d)
                    )
                })

                topResult && (
                    topResult.prevObjectType = topResult.ObjectType
                    , topResult['className'] = 'top results'
                    , topResult.ObjectType = getStaticText('top-results')
                    , topResult.Search1 = getStaticText('top-results') + topResult.Search1.slice(topResult.Search1.indexOf('~'))
                    , topResult.ObjectSeq = 0.5
                    , xs.unshift(topResult)
                )

                if (model.manuel.input) {
                    // append results to previous data if required
                    xs = model.manuel.input && model.nextUrl
                        ? model.items.concat(xs)
                        : xs

                    let types = xs.map(d => d.ObjectType)
                    types = types.filter((d, i) => i == types.indexOf(d))

                    let newXs = []
                    types.forEach(type => { newXs = newXs.concat(xs.filter(d => d.ObjectType == type)) })

                    xs = newXs
                }

                // update items
                model.items = xs
                model.manuel.list = model.items.map(o => o.Search1)
                m.redraw()

                // trigger next api to fetch furter items
                model.manuel.input
                    ? getNextData(nextApi)
                    : getNextData('')
            })
    }

    function getNextData(val) {
        model.nextUrl = val
        model.nextUrl && requesApi({
            method: 'GET'
            , url: model.nextUrl
        })
    }

    function sort(a, b) {
        model.prevCategory = ''
        //  Sorting based on 'ObjectSeq' then sort group in Ascending Order
        let categoryNameA = a.split('~')[0],
            categoryNameB = b.split('~')[0];

        let A = suggestionsByCategory(model.items)[categoryNameA].filter(d => d.Search1 == a)[0]
        let B = suggestionsByCategory(model.items)[categoryNameB].filter(d => d.Search1 == b)[0]

        if (A['ObjectSeq'] === B['ObjectSeq']) {
            let cond1, cond2;
            if (A['ObjectType'] == 'event') {
                cond1 = (new Date(A.EventDate)).getTime()
                cond2 = (new Date(B.EventDate)).getTime()
            } else {
                cond1 = A.Search1.substring(A.Search1.indexOf('~=') + 2).toLowerCase()
                cond2 = B.Search1.substring(B.Search1.indexOf('~=') + 2).toLowerCase()
            }
            if (cond1 < cond2) { return -1 }
            if (cond1 > cond2) { return 1 }
            return 0
        } else {
            return (A['ObjectSeq'] - B['ObjectSeq'])
        }
    }

    function choose(x) {
        let categoryIndex = x.indexOf('~=')
            , categoryName = x.substring(0, categoryIndex).split('~')[0]
            , data = suggestionsByCategory(model.items)[categoryName].filter(d => d.Search1 == x)[0];

        if (model.type == 'bolide') {
            window.open(model.urlRallySearch.replace('{selectedText}', data.ObjectKey), '_self')
        } else {
            let customEvent = new CustomEvent('suggestionSelected', { detail: data });
            document.dispatchEvent(customEvent)
        }
    }
}